import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="Complaints" />
    <div className="common-page privacy-page">
      <HeadingSection h1="Complaints" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h4>Making a Complaint to Sprive</h4>
      <p>
              At Sprive we are committed to providing a service of the very highest standard. We recognise that
        sometimes things can go wrong, and when they do, we want you to tell us so we can put them right
        as soon as possible. So if you’re unhappy with something, then please make sure you let us know by
        emailing us at <a href="mailto:hello@sprive.com" type="email">
                    hello@sprive.com
                  </a>.
      </p>
      <h4>Sprive's Complaints Process</h4>
      <p>
        When you contact us, we’ll aim to resolve the issue straight away.
      </p>
      <p>
        If we’re not sure whether you’d like to raise a formal complaint, we’ll check back with you to make
        sure we understand fully so we can address your request in the most appropriate way.</p>
      <p>
        Where we believe we can resolve your complaint within two working days, we will send you our
        response within that timeframe.</p>
      <p>
        If the issue is more complex or we can’t resolve the problem within two working days:</p>
        <p>
        <ul>
            <li>We’ll send you an email to confirm we’ve received your complaint within that timeframe</li>
            <li>We’ll always aim to provide a final response to your complaint within 15 calendar days. In
        exceptional circumstances, if there is a delay beyond 15 days, we’ll keep you posted on our progress
        explaining the reasons for the delay and the date on which we’ll provide a final response (which will
        be no later than 35 working days from the date we received your complaint).</li>
        </ul></p>
        <p>
        When we provide our final response, we’ll confirm our decision on your complaint, what we’ll do to
        put things right and information about the Financial Ombudsman Service.
        </p>
        <p>
        If you’re not satisfied with our final response, where you are eligible, you’ll have the right to refer
        your case to the Financial Ombudsman Service (FOS). Their address is: Financial Ombudsman
        Service, Exchange Tower, London, E14 9SR. More information about the service can be found on
        their website www.financial –ombudsman.org.uk or by calling them on free phone 0800 023 4567 or
        emailing them at complaint.info@financial-ombudman.org.uk. FOS also provide an online complaint
        form which is available on their website at <a
          href="https://help.financial-ombudsman.org.uk/help"
          target="_blank"
          rel="noopener noreferrer"
        >https://help.financial-ombudsman.org.uk/help</a>.
      </p>

      <h4>Online Dispute Resolution</h4>

      <p>
      The European Commission provides an online dispute resolution (ODR) platform which allows
customers who purchased a product or service online to submit their complaint through a central
site. The purpose of the site is to ensure those complaints are passed to the appropriate
independent dispute resolution service.
      </p>
      
      <p>
      If you feel that we haven’t been able to resolve your concerns, the appropriate service relating to
Sprive is the Financial Ombudsman Service. You can either contact them directly using the details
above or you can submit your complaint on the ODR platform at <a
          href="http://ec.europa.eu/odr"
          target="_blank"
          rel="noopener noreferrer"
        >http://ec.europa.eu/odr</a>.
      </p>


      <p>You will need the following information about us to use the ODR platform:</p>
      <p>Our name: Sprive Limited</p>
      <p>Our email: <a href="mailto:hello@sprive.com" type="email">
                    hello@sprive.com
                  </a></p>
      <p>Our website address: <a
          href="https://sprive.com"
          target="_blank"
          rel="noopener noreferrer"
        >https://sprive.com</a></p>
      <p>Our geographic address: United Kingdom</p>

      
    </div>
  </section>
);

export default PrivacyPage;
